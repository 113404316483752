import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { MatSnackBar } from '@angular/material';
import { isEmptyOrNull } from './utils';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  protected defaultController = `https://api.${environment.baseUrl}/api/`;
  protected slug = 'user';
  constructor(public http: HttpClient, private snackBar: MatSnackBar) {
  }

  public getAllUsers(pageNumber: number = 1, groupId: number, filterBy?: string, filter?: string): Observable<any> {
    let url = `${this.defaultController}${this.slug}?groupId=${groupId}`;
    if (pageNumber) {
      url += `&pageNumber=${pageNumber}`
    }
    if (filterBy && filter) {
      url += `&filterBy=${filterBy}&filter=${filter}`
    }
    return this.http.get(url);
  }

  public logout(): Observable<any> {
    return this.http.post(`${this.defaultController}${this.slug}/logout`, null);
  }

  public deleteUser(id: string): Observable<any> {
    return this.http.delete(`${this.defaultController}${this.slug}/${id}`).pipe(
      tap(response => {
        this.snackBar.open(response.result, 'Dismiss err10', {
          duration: 5000,
        });
      }));
  }

  public addUser(payload: any): Observable<any> {
    return this.http.post(`${this.defaultController}${this.slug}`, payload).pipe(
      tap(response => {
        this.snackBar.open(response.result, 'Dismiss err9', {
          duration: 5000,
        });
      }));
  }

  public getUser(id: string, suffix?: string): Observable<any> {
    return this.http.get(`${this.defaultController}${this.slug}/${id}${suffix ? '/' + suffix : ''}`);
  }

  public getUserOrders(id: string, pageNumber: number): Observable<any> {
    return this.http.get(`${this.defaultController}${this.slug}/${id}/order?pageNumber=${pageNumber}`);
  }

  public updateUser(id: string, payload: any, endpoint?: string, message?: string): Observable<any> {
    return this.http.put(`${this.defaultController}${this.slug}/${id}${isEmptyOrNull(endpoint) ? '' : '/' + endpoint}`, payload).pipe(
      tap(response => {
        this.snackBar.open(message ? message : response.result, 'Dismiss err8', {
          duration: 5000,
        });
      }));
  }

  public changePassword(payload: any): Observable<any> {
    return this.http.put(`${this.defaultController}${this.slug}/password`, payload).pipe(
      tap(response => {
        this.snackBar.open(response.result, 'Dismiss err7', {
          duration: 7000,
        });
      }));
  }

  public forgotPassword(payload: any): Observable<any> {
    return this.http.post(`${this.defaultController}${this.slug}/password`, payload).pipe(
      tap(response => {
        this.snackBar.open(response.result, 'Dismiss err6', {
          duration: 7000,
        });
      }));
  }

  public generatePassword(payload: any): Observable<any> {
    return this.http.post(`${this.defaultController}${this.slug}/password/generate`, payload).pipe(
      tap(response => {
        this.snackBar.open(response.result, 'Dismiss err5', {
          duration: 7000,
        });
      }));
  }

  public changeEmail(payload: any): Observable<any> {
    return this.http.put(`${this.defaultController}${this.slug}/email`, payload).pipe(
      tap(response => {
        this.snackBar.open(response.result, 'Dismiss err4', {
          duration: 7000,
        });
      }));
  }

  public activateUser(activate = true, payload: any): Observable<any> {
    return this.http.post(`${this.defaultController}${this.slug}/email/activation/${activate ? 'activate' : 'resend'}`, payload).pipe(
      tap(response => {
        this.snackBar.open(response.result, 'Dismiss err3', {
          duration: 5000,
        });
      }));
  }
}
