<div class="content-block generic-minigames">
    <div class="store-columns w-row">
        <div class="store-col1 w-col w-col-small-small-stack">
            <div class="content-block">
                <h1 class="content-heading">TERMS & ORDER PROCESS</h1>
                <div class="divider-medium">

                </div>
                <div class="store-col2 w-col w-col-12 w-col-small-small-stack">
                    <div class="rules">
                        Texttexttexttexttexttexttexttexttexttexttexttexttexttexttexttext
                        texttexttexttexttexttexttexttexttexttexttexttexttexttext
                        texttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttext
                        texttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttext
                        texttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttext
                        texttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttexttext
                    </div>
                </div>

                <button type="button" class="button w-button" (click)="onNoClick()"
                    style="width: 100%;margin-top: 10px;">
                    Proceed
                </button>
            </div>
        </div>
    </div>
</div>