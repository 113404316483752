import { JwtHelperService } from '@auth0/angular-jwt';
import { ErrorStateMatcher } from '@angular/material';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { CredentialsEnum } from './enums/Credentials.enum';
import * as mathjs from 'mathjs';

export function isEmptyOrNull(value) {
    return value === undefined || value === null || value === '';
}

export function getJwtToken() {
    return isEmptyOrNull(localStorage.getItem('jwt')) ? null : localStorage.getItem('jwt');
}

export function setPaymentGateways(input: any[]) {
    localStorage.setItem('paymentgateways', JSON.stringify(input));
}

export function getPaymentGateways() {
    return isEmptyOrNull(localStorage.getItem('paymentgateways')) ? [] : JSON.parse(localStorage.getItem('paymentgateways'));
}

export function isLoggedIn() {
    const helper = new JwtHelperService();
    if (!isEmptyOrNull(getJwtToken())) {
        return !helper.isTokenExpired(getJwtToken());
    }
    return false;
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

export function hasCredentials(credentials: CredentialsEnum): boolean {
    if (!isLoggedIn()) {
        return false;
    }
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(getJwtToken());
    if (isEmptyOrNull(decodedToken.groupId)) {
        return false;
    }
    return decodedToken.groupId === credentials;
}

export function getDecoded() {
    if (!isLoggedIn()) {
        return null;
    }
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(getJwtToken());
    return decodedToken;
}

export function openLiveChat() {
    const w: any = window;
    w.$zopim.livechat.window.show();
}

export function sendLiveChatMessage(message: string) {
    const w: any = window;
    w.$zopim.livechat.window.show();
    w.$zopim.livechat.say(message);
}

export const capitalizeFirstLetter = (input: string) => {
    return input.charAt(0).toUpperCase() + input.slice(1);
};

export function deepClone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj)) as T;
}


export function getAccountTypes() {
    return [
        {
            id: 1,
            name: 'Zerker'
        },
        {
            id: 2,
            name: 'Pure'
        },
        {
            id: 3,
            name: 'Main'
        },
        {
            id: 4,
            name: 'Staker'
        },
        {
            id: 5,
            name: 'Starter Build\'s'
        },
        {
            id: 6,
            name: 'Ironman'
        },
        {
            id: 7,
            name: 'HCIM'
        },
        {
            id: 8,
            name: 'PKer'
        },
        {
            id: 9,
            name: 'Skiller'
        }
    ]
}


export function round(input: number) {
    return +mathjs.round(input, 2);
}

export function setLocalStorageArrayIfNotDefined(key: string) {
    if (isEmptyOrNull(localStorage.getItem(key))) {
        localStorage.setItem(key, JSON.stringify([]));
    }
}

export function getLocalStorageArray(key: string) {
    return JSON.parse(localStorage.getItem(key));
}