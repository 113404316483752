import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { deepClone, getLocalStorageArray, isEmptyOrNull, round } from './utils';
@Injectable({
  providedIn: 'root'
})
export class CartService {
  subject: Subject<any> = new Subject<any>();
  constructor() {
  }

  public setItem(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
    this.subject.next();
  }

  public addItem(key: string, value: any) {
    let array = JSON.parse(localStorage.getItem(key));
    array.push(value);
    localStorage.setItem(key, JSON.stringify(array));
    this.subject.next();
  }

  public removeItem(key: string, keyId: string, idValue: any) {
    let array = JSON.parse(localStorage.getItem(key));
    array = array.filter(x => x[keyId] !== idValue);
    localStorage.setItem(key, JSON.stringify(array));
    this.subject.next();
  }

  public updateItem(key: string, keyId: string, idValue: any, newValue: any) {
    let array = JSON.parse(localStorage.getItem(key));
    if (this.isAdded(key, keyId, idValue)) {
      const index = this.getItemIndex(key, keyId, idValue);
      if (index !== -1) {
        array[index] = newValue;
        this.setItem(key, array);
        this.subject.next();
      }
    }
  }

  public isAdded(key: string, keyId: string, idValue: any) {
    let array = JSON.parse(localStorage.getItem(key));
    const found = array.find(x => x[keyId] === idValue);
    return found !== undefined && found !== null;
  }

  public getItemIndex(key: string, keyId: string, idValue: any) {
    let array = JSON.parse(localStorage.getItem(key));
    return array.map(x => x[keyId]).indexOf(idValue);
  }

  public getItem(key: string, keyId: string, idValue: any) {
    let array = JSON.parse(localStorage.getItem(key));
    return array.find(x => x[keyId] === idValue);
  }

  public get cartTotal() {
    const accounts = getLocalStorageArray('accounts');
    const powerleveling = getLocalStorageArray('powerleveling');
    const questing = getLocalStorageArray('questing');
    const minigames = getLocalStorageArray('minigames');
    const raids = getLocalStorageArray('raids');
    const bossing = getLocalStorageArray('bossing');
    let usd = 0;
    let osrs = 0;
    let items = 0;
    let totalAccountUsd = 0;
    let totalAccountOsrs = 0;
    let totalPowerlevelingUsd = 0;
    let totalPowerlevelingOsrs = 0;
    let totalQuestingUsd = 0;
    let totalQuestingOsrs = 0;
    let totalMinigamesUsd = 0;
    let totalMinigamesOsrs = 0;
    let totalRaidsUsd = 0;
    let totalRaidsOsrs = 0;
    let totalBossingUsd = 0;
    let totalBossingOsrs = 0;
    accounts.forEach(account => {
      totalAccountUsd += account.price;
      totalAccountOsrs += account.priceOsrs;
      items++;
      account.accountAddons.forEach(accountAddon => {
        totalAccountUsd += accountAddon.price;
        totalAccountOsrs += accountAddon.priceOsrs;
        items++
      });
    });

    powerleveling.forEach(p => {
      totalPowerlevelingUsd += p.price;
      totalPowerlevelingOsrs += p.priceOsrs;
      items++
    });

    questing.forEach(q => {
      totalQuestingUsd += q.price;
      totalQuestingOsrs += q.priceOsrs;
      items++
    });

    minigames.forEach(m => {
      m.services.forEach(s => {
        totalMinigamesUsd += s.price;
        totalMinigamesOsrs += s.priceOsrs;
        items++
      });
    });

    raids.forEach(r => {
      totalRaidsUsd += r.price;
      totalRaidsOsrs += r.priceOsrs;
      items++
    });

    bossing.forEach(r => {
      totalBossingUsd += r.quantity * r.price;
      totalBossingOsrs += r.quantity * r.priceOsrs;
      items++
    });

    // console.log(`Accounts: ${totalAccountUsd}`);
    // console.log(totalAccountOsrs);
    // console.log(`Powerleveling: ${totalPowerlevelingUsd}`);
    // console.log(totalPowerlevelingOsrs);
    // console.log(`Questing: ${totalQuestingUsd}`);
    // console.log(totalQuestingOsrs);
    // console.log(`Minigames: ${totalMinigamesUsd}`);
    // console.log(totalMinigamesOsrs);
    // console.log(`Raids: ${totalRaidsUsd}`);
    // console.log(totalRaidsOsrs);

    usd = round(totalAccountUsd + totalPowerlevelingUsd + totalQuestingUsd + totalMinigamesUsd + totalRaidsUsd + totalBossingUsd);
    // console.log(`Total: ${usd}`);
    osrs = round(totalAccountOsrs + totalPowerlevelingOsrs + totalQuestingOsrs + totalMinigamesOsrs + totalRaidsOsrs + totalBossingOsrs)

    return {
      usd,
      items,
      osrs
    }
  }
}
