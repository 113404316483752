import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { MatSnackBar } from '@angular/material';
import { isEmptyOrNull } from './utils';

@Injectable({
    providedIn: 'root'
})
export class TokenService {
    protected defaultController = `https://api.${environment.baseUrl}/api/`;
    protected slug = 'token';
    constructor(public http: HttpClient, private snackBar: MatSnackBar) {
    }

    public getAllTokens(pageNumber: number = 1): Observable<any> {
        return this.http.get(`${this.defaultController}${this.slug}?pageNumber=${pageNumber}`);
    }

    public deleteToken(id: string): Observable<any> {
        return this.http.delete(`${this.defaultController}${this.slug}/${id}`).pipe(
            tap(response => {
                this.snackBar.open(response.result, 'Dismiss err14', {
                    duration: 5000,
                });
            }));
    }

    public addToken(payload: any): Observable<any> {
        return this.http.post(`${this.defaultController}${this.slug}`, payload).pipe(
            tap(response => {
                this.snackBar.open(response.result, 'Dismiss err13', {
                    duration: 5000,
                });
            }));
    }

    public updateToken(id: number, payload: any): Observable<any> {
        return this.http.put(`${this.defaultController}${this.slug}/${id}`, payload).pipe(
            tap(response => {
                this.snackBar.open(response.result, 'Dismiss err12', {
                    duration: 5000,
                });
            }));
    }

    public revokeToken(id: number): Observable<any> {
        return this.http.put(`${this.defaultController}${this.slug}/${id}/revoke`, null).pipe(
            tap(response => {
                this.snackBar.open(response.result, 'Dismiss err11', {
                    duration: 5000,
                });
            }));
    }

    public getToken(id: string, endpoint?: string): Observable<any> {
        return this.http.get(`${this.defaultController}${this.slug}/${id}${isEmptyOrNull(endpoint) ? '' : '/' + endpoint}`);
    }
}