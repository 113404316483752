import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { MatSnackBar } from '@angular/material';
import { isEmptyOrNull } from './utils';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  protected defaultController = `https://api.${environment.baseUrl}/api/`;
  protected slug = 'order';
  constructor(public http: HttpClient, private snackBar: MatSnackBar) {
  }

  public getOrders(path: string, pageNumber: number = 1): Observable<any> {
    return this.http.get(`${this.defaultController}${this.slug}?type=${path}&pageNumber=${pageNumber}`);
  }

  public getOrder(orderId: string): Observable<any> {
    return this.http.get(`${this.defaultController}${this.slug}/${orderId}`);
  }

  public getOrdersCalendar(year: number, month: number): Observable<any> {
    return this.http.get(`${this.defaultController}${this.slug}/calendar?year=${year}&month=${month}`);
  }

  public deleteOrder(id: string): Observable<any> {
    return this.http.delete(`${this.defaultController}${this.slug}/${id}`).pipe(
      tap(response => {
        this.snackBar.open(response.result, 'Dismiss err32', {
          duration: 5000,
        });
      }));
  }

  public addOrder(payload: any, suffix: string): Observable<any> {
    return this.http.post(`${this.defaultController}${this.slug}/${suffix}?action=order`, payload)
  }

  public checkout(payload: any): Observable<any> {
    return this.http.post(`${this.defaultController}checkout`, payload)
  }

  public updateOrder(orderId: string, payload: any, type: string): Observable<any> {
    return this.http.put(`${this.defaultController}${this.slug}/${orderId}?type=${type}`, payload).pipe(
      tap(response => {
        this.snackBar.open(response.result, 'Dismiss err31', {
          duration: 5000,
        });
      }));
  }

  public requestOrder(orderId: string): Observable<any> {
    return this.http.post(`${this.defaultController}${this.slug}/${orderId}/request`, null).pipe(
      tap(response => {
        this.snackBar.open(response.result, 'Dismiss err30', {
          duration: 5000,
        });
      }));
  }
}
