import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StockService {
  protected defaultController = `https://api.${environment.baseUrl}/api/`;
  protected slug = 'stock';
  constructor(public http: HttpClient, private snackBar: MatSnackBar) {
  }

  public getStocks(pageNumber: number = 1): Observable<any> {
    return this.http.get(`${this.defaultController}${this.slug}?pageNumber=${pageNumber}`);
  }

  public getLatestStock(): Observable<any> {
    return this.http.get(`${this.defaultController}${this.slug}/latest`);
  }

  public addStock(payload: any): Observable<any> {
    return this.http.post(`${this.defaultController}${this.slug}`, payload).pipe(
      tap(response => {
        this.snackBar.open(response.result, 'Dismiss err16', {
          duration: 5000,
        });
      }));
  }
}
