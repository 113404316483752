import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { Router } from '@angular/router';
import { UserService } from './user.service';
import { getDecoded } from './utils';

@Injectable({
  providedIn: 'root'
})
export class WatcherService {
  subject: Subject<any> = new Subject<any>();
  subjectNotification: Subject<any> = new Subject<any>();

  subjectUser: Subject<any> = new Subject<any>();
  subjectNotificationUser: Subject<any> = new Subject<any>();
  constructor(private router: Router, private user: UserService) {
  }

  public change(data) {
    this.subject.next(data);
  }

  public get() {
    return this.subject.asObservable();
  }

  public logout(force = false) {
    if (force) {
      localStorage.clear();
      this.change({ jwt: null });
      this.router.navigate([`/login`]);
      setTimeout(() => {
        location.reload();
      }, 1000);
    } else {
      this.user.logout().subscribe(result => {
        localStorage.clear();
        this.change({ jwt: null });
        this.updateUser(null);
        this.router.navigate([`/login`]);
        setTimeout(() => {
          location.reload();
        }, 1000);
      });
    }
  }

  public updateUser(userData) {
    this.subjectNotificationUser.next(userData);
  }

  public getUpdatedUser() {
    return this.subjectNotificationUser.asObservable();
  }

  public refreshUser(): void {
    if (getDecoded()) {
      this.user.getUser(getDecoded().id).subscribe(response => {
        this.updateUser(response);
      });
    }
  }
}
