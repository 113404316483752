import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material';

@Injectable({
    providedIn: 'root'
})
export class GenericService {
    protected defaultController = `https://api.${environment.baseUrl}/api/`;
    constructor(public http: HttpClient, private snackBar: MatSnackBar) {
    }

    public getEndpoint(slug: string): Observable<any> {
        return this.http.get(`${this.defaultController}${slug}`);
    }
}
