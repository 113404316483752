import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders, InjectionToken } from '@angular/core';
import { MatCheckboxModule, MatNativeDateModule } from '@angular/material';
import { MatButtonModule } from '@angular/material';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSliderModule } from '@angular/material/slider';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource, MatTreeModule } from '@angular/material/tree';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WatcherService } from './watcher.service';
import { AuthInterceptor } from './interceptors/authinterceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserService } from './user.service';
import { GenericService } from './generic-service';
import { GenericTableComponent } from './components/generic-table/generic-table.component';
import { NgxNotifierModule } from 'ngx-notifier';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { WithdrawalRequestService } from './withdrawal-request.service';
import { OrderService } from './order.service';
import { NgxPaginationModule } from 'ngx-pagination';
import { AccountService } from './account.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from './cart.service';
import { TokenService } from './token.service';
import { StockService } from './stock.service';
import { ElementListingComponent } from './components/element-listing/element-listing.component';
import { SkillService } from './skill.service';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { CouponService } from './coupon.service';
import { BlogsGenericComponent } from './components/blogs-generic/blogs-generic.component';
import { BlogService } from './blog.service';
import { ProgressiveLoaderComponent } from './components/progressive-loader/progressive-loader.component';
import { QuillModule } from 'ngx-quill';
import { TrustpilotFeedbackComponent } from './components/trustpilot-feedback/trustpilot-feedback.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { AccountAddonService } from './account-addon.service';
import { RulesPopupComponent } from './components/rules-popup/rules-popup.component';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { TrustpilotMiniWidgetComponent } from './components/trustpilot-mini-widget/trustpilot-mini-widget.component';
import { TrustpilotMiniWidgetSecondComponent } from './components/trustpilot-mini-widget-second/trustpilot-mini-widget-second.component';
import { MinigameService } from './minigame.service';
import { HiscoreService } from './hiscore.service';
import { RaidService } from './raid.service';
import { FeedbackService } from './feedback.service';
import { BossingService } from './bossing.service';

@NgModule({
    declarations: [GenericTableComponent, ConfirmModalComponent, ElementListingComponent, BlogsGenericComponent, ProgressiveLoaderComponent, TrustpilotFeedbackComponent, PaymentMethodsComponent, RulesPopupComponent, SearchbarComponent, TrustpilotMiniWidgetComponent, TrustpilotMiniWidgetSecondComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        MatExpansionModule,
        MatSidenavModule,
        MatToolbarModule,
        MatCardModule,
        MatInputModule,
        MatFormFieldModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatListModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRadioModule,
        MatDatepickerModule,
        MatTabsModule,
        MatTableModule,
        FormsModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        ReactiveFormsModule,
        MatTreeModule,
        MatSnackBarModule,
        MatStepperModule,
        MatSortModule,
        MatDialogModule,
        NgxNotifierModule,
        NgxPaginationModule,
        MatNativeDateModule,
        NgbModule,
        FullCalendarModule,
        QuillModule
    ],
    exports: [
        MatIconModule,
        MatButtonModule,
        MatExpansionModule,
        MatSidenavModule,
        MatToolbarModule,
        MatCardModule,
        MatInputModule,
        MatFormFieldModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatMenuModule,
        MatListModule,
        MatSelectModule,
        MatCheckboxModule,
        MatRadioModule,
        MatDatepickerModule,
        MatTabsModule,
        MatTableModule,
        FormsModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatTreeModule,
        MatSnackBarModule,
        MatStepperModule,
        MatSortModule,
        MatDialogModule,
        GenericTableComponent,
        ConfirmModalComponent,
        NgxNotifierModule,
        NgxPaginationModule,
        NgbModule,
        MatNativeDateModule,
        FullCalendarModule,
        BlogsGenericComponent,
        ProgressiveLoaderComponent,
        QuillModule,
        TrustpilotFeedbackComponent,
        PaymentMethodsComponent,
        SearchbarComponent,
        TrustpilotMiniWidgetComponent,
        TrustpilotMiniWidgetSecondComponent
    ],
    entryComponents: [ConfirmModalComponent, RulesPopupComponent],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
                UserService,
                WatcherService,
                GenericService,
                OrderService,
                AccountService,
                CartService,
                TokenService,
                StockService,
                SkillService,
                CouponService,
                BlogService,
                WithdrawalRequestService,
                AccountAddonService,
                MinigameService,
                HiscoreService,
                RaidService,
                FeedbackService,
                BossingService
            ]
        };
    }
}
