import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
@Injectable({
  providedIn: 'root'
})
export class HiscoreService {
  protected defaultController = `https://api.${environment.baseUrl}/api/`;
  protected slug = 'hiscore';
  constructor(private http: HttpClient) {
  }

  public getHiscores(endpoint: string, username: string): Observable<any> {
    return this.http.get(`${this.defaultController}${this.slug}?endpoint=${endpoint}&username=${username}`);
  }
}
