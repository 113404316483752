import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { BasePageComponent } from 'src/app/core/base-page.component';

@Component({
  selector: 'app-rules-popup',
  templateUrl: './rules-popup.component.html',
  styleUrls: ['./rules-popup.component.sass']
})
export class RulesPopupComponent extends BasePageComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<RulesPopupComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close(null);
  }
}

